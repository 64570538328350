<template>
  <div>
    <app-header />

    <p id="privacy-start"></p>

    <section class="privacy-policy container">
      <h1>PRIVACY POLICY</h1>

      <h2>What is Personal Information?</h2>

      <p>
        Personal information is any information that identifies you, or by which
        your identity could be deduced. This may include information such as
        your address, phone number, email address, payment details (for example,
        your credit card information), transaction history and any other
        information you provide us (<b>“Personal Information”</b>).
      </p>

      <p>
        RoadRunner Technologies Inc. (hereinafter, <b>“RR”</b>, <b>“we”</b> or
        <b>“us”</b>) treats your personal information with respect, and in
        accordance with this Privacy Policy and applicable laws. This Privacy
        Policy explains how we collect, use, store, and retain your Personal
        Information when you register for an account or place an order for
        delivery (the <b>“Services”</b>) as well as when you use, visit and
        access our website, mobile application, and our social media pages
        (collectively the <b>“Sites”</b>).
      </p>

      <p>
        As outlined in our
        <router-link to="/terms-and-conditions/#terms-start"
          >Terms and Conditions</router-link
        >, by purchasing/using our Services, visiting our Sites or contacting us
        for customer support (i.e. by email, phone, filling out forms or
        otherwise), you agree to the terms of this Privacy Policy and consent to
        the policies and practices described herein for the collection, use or
        disclosure of your Personal Information
      </p>

      <h2>What Personal Information Do We Collect and Why Do We Collect It?</h2>

      <p>
        We collect and store any information that you provide us directly when
        you create an account, make a purchase through our Services, visit our
        Sites or contact customer support.
      </p>

      <p>
        When you visit our Sites or use our Services, we may collect certain
        kinds of user information, such as IP addresses, browser and device
        types, and which pages or content you access. This information may be
        associated with you, used to deliver our Services, and provide technical
        troubleshooting. Certain parts of our Sites use cookies to enable our
        systems to recognize your browser and to provide convenience and other
        features to you, such as recognizing you as an existing customer or
        previous visitor.
      </p>

      <p>
        We use your Personal Information to: (a) fulfill any orders placed
        through the Sites and deliver our Services; (b) communicate with you
        from time to time via email; (c) improve and optimize our Sites (for
        example, by generating analytics about how visitors browse and interact
        with the Sites, and to assess the success of our marketing and
        advertising campaigns); (d) include you in any direct marketing
        activities (after obtaining your express consent, if done
        electronically); and (e) other business purposes.
      </p>

      <p>
        When you make a payment to us, we use third-party services to process
        payments and do not have access to your payment information. These
        third-party services will be responsible for securely managing your
        personal and financial data in accordance with payment card industry
        data security standards (also known as PCI DSS).
      </p>

      <h2>Sharing Information</h2>

      <p>
        We are committed to protecting your privacy. We will not disclose your
        Personal Information to any third-party to enable them to market their
        products and services and we will not sell your Personal Information
        under any circumstances.
      </p>

      <p>
        By consenting to this Privacy Policy, you agree that your Personal
        Information may be accessed and used in the following ways: (a) by
        internal employees and independent contractors of RR, and by merchants
        you place orders with through our Services, in order to confirm your
        purchases, track your orders, make deliveries to you, contact you about
        issues or changes regarding your orders, and otherwise to provide our
        Services and respond to inquiries; (b) to understand our customer
        demographics and preferences, and communicate with you; (c) by
        third-party service providers to provide certain services, such as
        processing payment card information, web hosting, and email service
        providers; and (d) by companies that we share information with,
        including ad networks, which may use the information we provide to them,
        often in combination with their existing information, to provide you
        with more relevant advertising, and to help us and third parties do the
        same.
      </p>

      <p>
        We may also share your Personal Information to comply with applicable
        laws and regulations, to respond to a subpoena, search warrant or other
        lawful request for information we receive, or to otherwise protect our
        rights.
      </p>

      <h2>How Long Do We Keep Your Information?</h2>

      <p>
        We retain your Personal Information as long as required to provide our
        Services, or as long as required for us to fulfill our business
        requirements, agreements, contracts, and legal obligations, whichever is
        later. When you place an order through the Sites, we will maintain your
        order information for our records unless and until you ask us to delete
        this information. If you wish to update, review, or validate any other
        Personal Information, please contact us at
        <a href="mailto:info@runningtheroad.ca">info@runningtheroad.ca</a>.
      </p>

      <h2>How Do We Get Your Consent?</h2>

      <p>
        RR does not collect, use or disclose your Personal Information without
        your consent except as and when permitted or required by law. By
        providing us with your Personal Information, using our Sites and/or
        Services, you consent to our collection, use and disclosure of such
        personal information in accordance with this Privacy Policy and as
        otherwise permitted or required by law.
      </p>

      <h2>How Do We Keep Your Information Safe?</h2>

      <p>
        RR takes commercially reasonable precautions to protect your Personal
        Information from loss, theft, unauthorized access, disclosure, copying,
        use or modification. Among the steps taken to protect your information
        are: (a) premises security; and (b) internal password and security
        policies. You are responsible for keeping your devices secure to prevent
        unauthorized use and access of your Personal Information. Note that
        e-mail is not a 100% secure medium, and you should be aware of this when
        sending any information to us.
      </p>

      <h2>What If We Change Our Privacy Policy?</h2>

      <p>
        We reserve the right to change our Privacy Policy from time to time. It
        is your responsibility to review our Privacy Policy for any changes. You
        understand and agree that your continued use of the Sites or our
        Services constitutes your agreement to any changes.
      </p>

      <p>
        Note that our Sites may contain links to other websites, which are not
        governed by this Privacy Policy.
      </p>

      <h2>Do You Have Any Other Questions?</h2>

      <p>Please contact us if you have any questions or requests.</p>

      <p>
        Contact Email:
        <a href="mailto:info@runningtheroad.ca">info@runningtheroad.ca</a>
      </p>
    </section>

    <app-footer />
  </div>
</template>

<script>
  import AppHeader from '../components/RoadrunnerLanding/AppHeader'
  import AppFooter from '../components/RoadrunnerLanding/AppFooter'

  export default {
    name: 'PrivacyPolicy',

    components: {
      AppHeader,
      AppFooter,
    },
  }
</script>

<style lang="scss" scoped>
  .privacy-policy {
    min-height: 55vh;
    padding-top: 120px;
    box-sizing: border-box;
    max-width: 850px;
    text-align: justify;
    font-family: CabinetGrotesk-Regular;

    h1 {
      text-align: center;
      margin-bottom: 45px;
    }

    h2 {
      font-size: 20px;
    }
  }
</style>
